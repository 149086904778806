import React, { useEffect, useState } from "react";
import styles from "./Transfer.module.scss";
import OperationsRightBox from "../../../Common/OperationsRightBox/OperationsRightBox";
import TransferLeftBox from "./TransferLeftBox/TransferLeftBox";
import MobileChooser from "../../../Common/MobileChooser/MobileChooser";
import { getCoinSettings, getQuestions } from "../../../../api/api";
import { useAppSelector } from "../../../../hooks/redux";
import { getText } from "../../../../store/selectors";
import useWindowSize from "../../../../hooks/useWindowSize";

const Transfer = () => {
    const { rightBox } = useAppSelector(getText);
    const { width } = useWindowSize();

    const [isLeft, setIsLeft] = useState(true);
    const [questionsList, setQuestionsList] = useState([]);
    const [token, setToken] = useState(null);
    const [settings, setSettings] = useState(null);

    useEffect(() => {
        const fetchQuestions = async () => {
            if (questionsList.length === 0) {
                const res = await getQuestions("transfer");
                if (res?.length > 0) setQuestionsList(res);
            }
        };

        fetchQuestions();
    }, [questionsList]);

    useEffect(() => {
        const fetchSettings = async () => {
            if (token) {
                const result = await getCoinSettings(token.label, "TRANSFER");
                if (result) setSettings(result);
            }
        };

        fetchSettings();
    }, [token]);

    const isTablet = width > 743 && width < 1024;
    const isDesktop = width >= 1024;

    return (
        <div className={styles.wrapper}>
            {isTablet && (
                <MobileChooser
                    isLeft={isLeft}
                    setIsLeft={setIsLeft}
                    leftText="Workspace"
                    rightText="Questions and answers"
                />
            )}
            <div className={styles.transfer}>
                {(isDesktop || isLeft) && (
                    <div className={`${styles.transfer__leftBox} box`}>
                        <TransferLeftBox token={token} updateToken={setToken} settings={settings} />
                    </div>
                )}
                {(isDesktop || !isLeft) && (
                    <div className={`${styles.transfer__rightBox} box`}>
                        {token && settings && (
                            <OperationsRightBox
                                questionsList={questionsList}
                                text={rightBox}
                                token={{
                                    name: token.fullName,
                                    label: token.label,
                                    minimum: settings?.settings?.minTransferAmount,
                                    operation: rightBox.minimumTransfer,
                                }}
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Transfer;
