import React, {useCallback, useEffect, useState} from "react";
import SpotHeaderToken from "./SpotHeaderToken/SpotHeaderToken";
import styles from './SpotTotalChanger.module.scss';
import {getIsDarkTheme, getPictureSrc} from "../../../../../../store/selectors";
import {useAppSelector} from "../../../../../../hooks/redux";
import classNames from "classnames";
import {useParams} from "react-router-dom";
import PairItem from "./SpotHeaderToken/PairItem";


const SpotTotalChanger = ({text, pair, changePair, pairList}) => {
    const {coin} = useParams();
    const isDark = useAppSelector(getIsDarkTheme);
    const pictureSrc = getPictureSrc;
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (!pairList?.length) return;

        const storedPair = localStorage.getItem("selectedPair");
        const selectedPair = storedPair ? JSON.parse(storedPair) : pairList[0];

        if (!pair || pair.base !== selectedPair.base) {
            changePair(selectedPair);
            localStorage.setItem("selectedPair", JSON.stringify(selectedPair));
        }
    }, [pairList]);

    const onChangePair = useCallback((value) => {
        changePair(value);
        localStorage.setItem("selectedPair", JSON.stringify(value));
    }, [changePair]);


    useEffect(() => {
        if (!coin) return;
        const matchedPair = pairList.find((item) => item.base.toLowerCase() === coin);
        if (matchedPair) {
            changePair(matchedPair);
        }
    }, [pairList])


    return (
        <div className={styles.spotTotalChanger} onClick={() => setShow((prev) => !prev)}>
            {pair && <SpotHeaderToken text={text} pair={pair} pictureSrc={pictureSrc} />}

            {pair && (
                <div className={styles.pairsContent}>
                    <div className={classNames(styles.content, "spotBg", { [styles.contentActive]: show })}>
                        {pairList.map((item) => (
                            <PairItem
                                key={item.base} // ✅ Used a unique identifier instead of index
                                item={item}
                                pair={pair}
                                isDark={isDark}
                                pictureSrc={pictureSrc}
                                text={text}
                                onChangePair={onChangePair}
                            />
                        ))}
                    </div>
                </div>
            )}

            {show && <button className="closer" onClick={() => setShow(false)} />}
        </div>
    );
}

export default SpotTotalChanger;