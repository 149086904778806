import React, { useState, useMemo } from "react";
import { NavLink } from "react-router-dom";
import styles from "./FooterNavigation.module.scss";
import FooterMenu from "./FooterMenu/FooterMenu";
import FooterMenuBtn from "./FooterMenu/FooterMenuBtn";
import mainIcon from "../../../assets/icons/homeFooter.svg";
import spotIcon from "../../../assets/icons/spotFooter.svg";
import spotIconLight from "../../../assets/icons/spotFooterLight.svg";
import buyIcon from "../../../assets/icons/buyFooter.svg";
import buyIconLight from "../../../assets/icons/buyFooterLight.svg";
import { useAppSelector } from "../../../hooks/redux";
import { getIsDarkTheme } from "../../../store/selectors";
import classNames from "classnames";

const FooterNavigation = () => {
    const isDark = useAppSelector(getIsDarkTheme);
    const [activeMenu, setActiveMenu] = useState(false);

    const navigation = useMemo(() => [
        { name: "Main", icon: mainIcon, url: "/" },
        { name: "Spot", icon: isDark ? spotIcon : spotIconLight, url: "/spot" },
        { name: "Wallets", icon: isDark ? buyIcon : buyIconLight, url: "/wallet_connect" },
    ], [isDark]);

    return (
        <div className={classNames(styles.footerNavigation, "footerNavigation")}>
            <FooterMenu activeMenu={activeMenu} setActive={setActiveMenu} />
            <FooterMenuBtn setActive={setActiveMenu} />
            <div className={styles.linksBox}>
                {navigation.map(({ name, icon, url }, index) => (
                    <NavLink to={url} key={index} className={styles.link}>
                        <img src={icon} alt={name} />
                        <span>{name}</span>
                    </NavLink>
                ))}
            </div>
        </div>
    );
};

export default FooterNavigation;
