import React from "react";
import styles from './ExchangeTrade.module.scss';
import exchangeImg from '../../../../assets/backgrounds/exvhangeBgOpt.png';
import {useAppSelector} from "../../../../hooks/redux";
import {getWebsiteName} from "../../../../store/selectors";

const ExchangeTrade = ({text}) => {
    const webName = useAppSelector(getWebsiteName);

    return (
        <div className={styles.exchangeTrade}>

            <div className={styles.exchangeTrade__header}>
                <span>{text.exchangeTop}</span>
                <h3>{text.exchangeTitle} {webName}</h3>
            </div>
            <img className={styles.decor} src={exchangeImg} alt=""/>

            <div className={styles.exchangeTrade__content}>
                <div className={styles.exchangeTrade__contentBox}>
                    <p>{text.exchangeDescription1} {webName} {text.exchangeDescription2} {webName}</p>
                    <div className={styles.exchangeTrade__actions}>
                        <button className={`${styles.linkComp} blueBtn`}>
                            <span>{text.exchangeBtnComputer}</span>
                        </button>
                        <button className={styles.linkmob}>
                            <span>{text.exchangeBtnMobile}</span>
                        </button>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ExchangeTrade;