import React from "react";
import styles from './TradingTable.module.scss';

const TradingTableHeader = () => {
    return (
        window.innerWidth > 767 && (
            <div className={styles.header}>
                <span>Name</span>
                <span>Price</span>
                <span>24h change</span>
                <span>Trade</span>
            </div>
        )
    );
};

export default TradingTableHeader;