import React, {useEffect, useState} from "react";
import SpotTotalChanger from "./SpotTotalChanger";
import {getSpotTokenPairs} from "../../../../../../api/api";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks/redux";
import {getAllPairs} from "../../../../../../store/selectors";
import {setSpotPairs} from "../../../../../../store/main-slice";


const SpotTotalChangerWrapper = ({text, pair, changePair}) => {
    const dispatch = useAppDispatch();
    const pairList = useAppSelector(getAllPairs);

    useEffect(() => {
        getSpotTokenPairs().then((response) => {
            if (response && response.length > 0) {
                dispatch(setSpotPairs(response));
            }
        })
    }, [])

    return (
        <div>
            {pairList && (
                <SpotTotalChanger text={text} pair={pair} changePair={changePair} pairList={pairList} />
            )}
        </div>
    )
}

export default SpotTotalChangerWrapper;