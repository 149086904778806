import React from "react";
import styles from './TradingPlatform.module.scss';
import tradeBG from '../../../../assets/backgrounds/tradePlatformBGOpt.svg'
import TradingTable from "./TradingTable/TradingTable";

const TradingPlatform = ({title, subTitle}) => {
    return (
        <div className={styles.tradingPlatform}>

            <div className={styles.tradingPlatform__header}>
                <h3>{title}</h3>
                <p>{subTitle}</p>
            </div>
                <div className={styles.tradingPlatform__content}>
                    <img className={styles.decor} src={tradeBG} alt=""/>
                    {/*<span className={styles.decor} style={{backgroundImage: `url(${tradeBG})`}}/>*/}
                    <TradingTable />
                </div>
        </div>
    )
}

export default TradingPlatform;