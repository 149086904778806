import React from "react";
import styles from './NotificationContent.module.scss';
import arrow from "../../../../../assets/icons/arrowRightLight.svg";


const NotificationItem = ({item}) => {

    return (
        <div className={`${styles.item} notificationItem`}>
            <div className={styles.item__header}>
                <div className={styles.item__icon}>
                    <img src={item.icon} alt=""/>
                </div>
                {window.innerWidth < 743 && (
                    <img className={styles.arrow} src={arrow} alt=""/>
                )}
                <p>{item.title}</p>
            </div>
            <p className={styles.item__text}>{item.text}</p>
            <p className={styles.item__date}>{item.date}</p>
        </div>
    )
}

export default NotificationItem;