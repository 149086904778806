import React from 'react';
import styles from './Preloader.module.scss';
import {useAppSelector} from "../../../hooks/redux";
import {getWebsiteName} from "../../../store/selectors";

const PreloaderLogo = () => {
    const webName = useAppSelector(getWebsiteName);
    return (
        <div className={`${styles.preloaderContainer} startLoading`}>
            <div className={styles.spinner}/>
            <h1 className={styles.name}>{webName}</h1>
        </div>
    );
};

export default PreloaderLogo;
